import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Link from 'next/link'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import NoSsr from '@material-ui/core/NoSsr'
import CoachProfileCtaBanner from '@components/coach-profile-cta-banner'
import TestimonialV2 from '@components/testimonialv2'
import { useSendImpressionEvent } from '@lib/gtm-utils'
import CoachOfferingGroupEvents from '../coach-offering-group-events'
import CoachOfferingPrograms from '../coach-offering-programs'
import CoachBio from '../coach-bio/coach-bio'
import CoachPhotos from '../coach-photos'
import CoachExperiences from '../coach-experiences'
import CoachServices from '../coach-services'
import CoachSkillsTools from '../coach-skills-tools'
import CoachFacilities from '../coach-facilities'
import CoachArticlesQuery from '../coach-articles'
import useStyles from './coaches-page.styles'

const CoachesPage = (props) => {
  const styles = useStyles()
  const { coach } = props

  useEffect(() => {
    const { introductionVideoUrl } = coach
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'coach-profile-view',
      info: [{ 'has-video': !!introductionVideoUrl }],
    })
  }, [coach])

  const findExperiences = (experienceTypes, typeName) => {
    const experience = experienceTypes.find(exp => exp.typeName === typeName)
    if (experience) {
      return experience.experiences.map(opt => opt.experienceName)
    }
  }

  const findServices = (serviceTypes, typeName) => {
    const service = serviceTypes.find(serv => serv.typeName === typeName)
    if (service) {
      return service.services.map(opt => opt.serviceName)
    }
  }

  useSendImpressionEvent({
    event: 'view-coach-profile',
    attributes: {
      coach_name: (coach?.firstName && coach?.lastName)
        ? `${coach?.firstName} ${coach?.lastName}`
        : `${coach?.displayName}`,
      has_video: !!coach?.introductionVideoUrl,
      has_profile_photo: !!coach?.profilePhoto,
      city: coach?.city,
      state: coach?.state,
      facility: coach?.facilities.length > 0 ? coach?.facilities[0].facilityName : null,
      bookable: coach?.bookable,
      coach_id: coach?.coach?.id,
      gender: coach?.gender,
      player_type: findServices(coach?.serviceTypes, "Player Type"),
      session_type: findServices(coach?.serviceTypes, "Session Type"),
      getting_started: findExperiences(coach?.experienceTypes, "Getting Started"),
      having_more_fun: findExperiences(coach?.experienceTypes, "Having More Fun"),
      improving_your_game: findExperiences(coach?.experienceTypes, "Improving Your Game"),
      students: coach?.numberOfStudents,
      followers: coach?.numberOfFollowers,
      socials: coach?.socialJsonData?.length > 0 ? coach?.socialJsonData.map(social => social.type) : null,
      has_photos: coach?.photosJsonData?.length > 0,
      coaching_available_to_public: coach?.facilities.some(fac => fac.isPrivate === false),
      has_quotes: coach?.testimonialsJsonData?.length > 0,
    },
  })

  const profileIsActive = coach.status === 'ACTIVE'

  return (
    <div className={styles.root}>

      {profileIsActive && <CoachProfileCtaBanner coach={coach} />}

      {/* Main Profile Section */}
      <CoachBio coach={coach} />

      <NoSsr>
        <CoachOfferingPrograms coach={coach} />
      </NoSsr>

      {/* Coach Group Events  */}
      <NoSsr>
        <CoachOfferingGroupEvents coach={coach} />
      </NoSsr>


      {/* Photos */}
      {coach.photosJsonData && coach.photosJsonData.length > 0 && (
        <CoachPhotos photos={coach.photosJsonData} firstName={coach.firstName} />
      )}
      {/* Testimonials */}
      {coach.testimonialsJsonData && coach.testimonialsJsonData.length > 0 && (
        <TestimonialV2 testimonialsData={coach.testimonialsJsonData} />
      )}
      {/* Experiences */}
      <CoachExperiences experiences={coach.experienceTypes} firstName={coach.firstName} />
      {/* Services */}
      <CoachServices services={coach.serviceTypes} />
      {/* Skills and Tools */}
      <CoachSkillsTools
        skills={coach.skills}
        tools={coach.toolJsonData}
        firstName={coach.firstName}
      />
      {/* Coach Articles */}
      <CoachArticlesQuery coach={coach} />
      {/* Facilities */}
      <CoachFacilities facilities={coach.facilities} coach={coach} />
      {/* Bottom of the page CTA */}
      <Box className={styles.bottomCTA}>
        <Link href="/coaches/search">
          <Button variant="outlined" color="secondary">
            Explore More Coaches →
          </Button>
        </Link>
      </Box>
    </div>
  )
}

const gendersList = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'O', label: 'Other' },
]

CoachesPage.propTypes = {
  coach: PropTypes.shape({
    coachProfileId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    displayName: PropTypes.string,
    title: PropTypes.string,
    gender: PropTypes.oneOf([...gendersList, ...gendersList.map(g => g.value)]),
    profilePhoto: PropTypes.string,
    experienceTypes: PropTypes.array,
    serviceTypes: PropTypes.array,
    customSlug: PropTypes.string,
    phoneExt: PropTypes.string,
    mobileNumber: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bio: PropTypes.string,
    numberOfStudents: PropTypes.number,
    numberOfFollowers: PropTypes.number,
    socialJsonData: PropTypes.array,
    testimonialsJsonData: PropTypes.array,
    photosJsonData: PropTypes.array,
    toolJsonData: PropTypes.array,
    status: PropTypes.string,
    facilities: PropTypes.array,
    experience: PropTypes.array,
    service: PropTypes.array,
    skills: PropTypes.array,
    bookable: PropTypes.bool,
    introductionVideoUrl: PropTypes.string,
    coach: PropTypes.object,
  }),
}

CoachesPage.defaultProps = {
  coach: {
    coachProfileId: '',
  },
}

export default CoachesPage
