import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { yupResolver } from '@hookform/resolvers'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
// eslint-disable-next-line no-restricted-imports
import { shuffle } from 'lodash'
import { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import Recaptcha from '@components/recaptcha'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import MarketingOptIn from '@components/marketing-opt-in'
import { useFeatureFlags, flags } from '@lib/feature-flags'
import { gtmEvent } from '@lib/gtm-utils'
import EmailFieldWithSuggestions from '@components/form-utils/email-field-with-suggestions'

const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(50)
    .required('First name is required')
    .label('First name'),
  lastName: Yup.string()
    .trim()
    .max(100)
    .required('Last name is required')
    .label('Last name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .max(100)
    .required('Email is required')
    .label('Email'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Please enter a valid phone number')
    .label('Phone number'),
  interest: Yup.string().required('You must select an interest'),
})

const intentOptions = shuffle([
  'Help me shoot lower scores',
  'Fix a specific problem in my golf game',
  'Help my junior golfer improve',
  'Connect me with other golfers',
])

const introductionOptions = [
  'Introduce me to golf',
  'Introduce my child to golf',
  'Introduce my family to golf',
]

const insertIndex = Math.floor(Math.random() * intentOptions.length)

intentOptions.splice(insertIndex, 0, introductionOptions)

const LeadForm = ({ coach }) => {
  const classes = useStyles()
  const [submitted, setSubmitted] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const source = 'PGACOM_PROFILE_DIRECT'
  const [termsAndPrivacyAccepted, setTermsAndPrivacyAccepted] = useState(false)
  const [marketingOptInChecked, setMarketingOptInChecked] = useState(false)
  const [marketingOptIn] = useFeatureFlags([
    flags.FLAG_MARKETING_OPT_IN_EXP,
  ])

  const coachName = (coach.firstName && coach.lastName) ? `${coach.firstName} ${coach.lastName}` : coach.displayName

  useEffect(() => {
    if (submitted) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [submitted])


  const COACHING_LEAD_CREATE = gql`
    mutation coachingLeadCreate($params: CoachingLeadCreateRequest!) {
      CoachingLeadCreate(params: $params) {
        success
        message
      }
    }
  `

  const {
    register,
    control,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const [coachingLeadCreate, { error: submitError }] = useMutation(
    COACHING_LEAD_CREATE,
    {
      context: { clientName: 'coach-tools', recaptchaToken },
      onError: () => { },
      onCompleted: (data) => {
        const leadEmail = getValues('email')
        const leadIntent = getValues('interest')

        if (data.CoachingLeadCreate.success) {
          setSubmitted(true)
          gtmEvent({
            event: 'formSubmit',
            attributes: {
              formSource: source,
              formCategory: 'lead-submitted',
              formAction: 'lead-form-submit',
            },
          })
          gtmEvent({
            event: 'complete-lead-submission',
            attributes: {
              coach_name: coachName,
              coach_id: coach?.coach.id,
              facility: coach?.facilities.length > 0 ? coach.facilities[0].facilityName : null,
              city: coach?.city,
              state: coach?.state,
              lead_email: leadEmail,
              lead_intent: leadIntent,
              source: source,
            },
          })
          if (marketingOptInChecked) {
            gtmEvent({
              event: 'marketing-opt-in',
              attributes: {
                source: 'embedded_lead_form',
                variant: marketingOptIn,
              },
            })
          }
        } else {
          // eslint-disable-next-line no-use-before-define
          errors.push(data.CoachingLeadCreate.message)
        }
      },
    },
  )

  useEffect(() => {
    if (marketingOptIn) {
      setTermsAndPrivacyAccepted(true)
    }
  }, [marketingOptIn])

  const handleMarketingOptInChange = (isChecked) => {
    setMarketingOptInChecked(isChecked)
  }

  const onSubmit = async data => {
    const marketingOptInAt = marketingOptInChecked
      ? new Date().toISOString()
      : null


    coachingLeadCreate({
      variables: {
        params: {
          coachSlug: coach.customSlug,
          email: data.email,
          phoneNumber: `+1${data.phoneNumber.replace(/\D/g, '')}`,
          firstName: data.firstName,
          lastName: data.lastName,
          intent: data.interest,
          source,
          marketingOptInAt: marketingOptInAt,
        },
      },
    })
  }

  if (submitted) {
    return (
      <Container
        data-testid="lead-form"
      >
        <Grid container justifyContent="center">
          <Grid item md={6}>
            <Box pb={1}>
              <Typography style={{ textAlign: 'center' }} variant="h3">Thanks for your interest!</Typography>
            </Box>
            <Box>
              <Typography style={{ textAlign: 'center' }}>
                Your information has been shared with Coach{' '}
                {coach.firstName}. You will also receive an email
                confirmation.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  }

  return (
    <Container
      data-testid="lead-form"
      id="lead-form"
    >
      <Grid container justifyContent="center">
        <Grid item md={8} sm={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography className={classes.contactOverline}>
                INTERESTED IN COACHING?
              </Typography>
              <Typography variant="h4">
                Contact Coach {coach.firstName}
              </Typography>
            </Box>

            <Box mt={4}>
              <Box mb={1}>
                <Typography variant="subtitle1">Your Information</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      name="firstName"
                      label="First name"
                      required
                      error={errors.firstName}
                      helperText={errors.firstName?.message}
                      inputRef={register}
                      variant="outlined"
                      className={classes.formInput}
                      autoComplete="given-name"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      name="lastName"
                      label="Last name"
                      required
                      error={errors.lastName}
                      helperText={errors.lastName?.message}
                      inputRef={register}
                      variant="outlined"
                      className={classes.formInput}
                      autoComplete="family-name"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      name="phoneNumber"
                      label="Phone number"
                      required
                      type="tel"
                      error={errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                      inputRef={register}
                      variant="outlined"
                      className={classes.formInput}
                      autoComplete="tel-national"
                      inputProps={{
                        maxLength: 13,
                      }}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/[^\d- ]/g, '')
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <EmailFieldWithSuggestions
                      fieldName="email"
                      control={control}
                      errors={errors}
                      label="Email"
                      variant="outlined"
                      className={classes.formInput}
                      fullWidth
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box mt={2} mb={5}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>What can {coach.firstName} help you with?</Typography>
                <FormControl style={{ borderRadius: '4px', background: 'white' }} variant="outlined" fullWidth>
                  <InputLabel id="interest-label">Select a goal *</InputLabel>
                  <Controller
                    defaultValue=""
                    name="interest"
                    control={control}
                    as={(
                      <Select
                        name="interest"
                        required
                        label="Select a goal *"
                        id="select-interest"
                        labelId="interest-label"
                        error={errors.interest}
                      >
                        {intentOptions.flat().map(opt => (
                          <MenuItem key={opt} value={opt}>
                            {opt}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              {marketingOptIn && (
                <MarketingOptIn
                  checked={marketingOptInChecked}
                  onChange={handleMarketingOptInChange}
                  useMarketingOptIn={marketingOptIn}
                />
              )}

              {!marketingOptIn && (
                <Grid item xs={12}>
                  <FormControl required margin="normal">
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="termsAndPrivacy"
                          required
                          onChange={e => setTermsAndPrivacyAccepted(e.target.checked)}
                          error={errors.termsAndPrivacy}
                        />
                      )}
                      label={(
                        <Typography variant="body2">
                          By checking this box, you are agreeing to
                          PGA of America&apos;s <a target="_blank" href="/pga-of-america/privacy-policy">Privacy Policy</a> and <a target="_blank" href="/pga-of-america/terms-of-service">Terms of Service</a>.
                        </Typography>
                      )}
                    />
                    {errors.termsAndPrivacy && (
                      <FormHelperText error>
                        {errors.termsAndPrivacy.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}

              {marketingOptIn && (
                <Typography variant="body2" className={classes.proceedingText}>
                  By proceeding, you agree to PGA of America’s{' '}
                  <Link
                    className={classes.link}
                    href="https://www.pga.com/pga-of-america/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link
                    className={classes.link}
                    href="https://www.pga.com/pga-of-america/terms-of-service"
                    target="_blank"
                  >
                    Terms of Service
                  </Link>
                  .
                </Typography>
              )}
              <Grid item xs={12}>
                <FormControl margin="normal">
                  <Recaptcha setToken={setRecaptchaToken} />
                  {submitError && (
                    <FormHelperText error>
                      An error has occurred. Please try again later.
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting || !recaptchaToken || !termsAndPrivacyAccepted}
            >
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LeadForm

LeadForm.propTypes = {
  coach: PropTypes.shape({
    customSlug: PropTypes.string,
    firstName: PropTypes.string,
  }),
}
