import PropTypes from 'prop-types'

const CoachLogo = ({ memberType, certificationLevel, size }) => {
  const getPgaCrest = {
    CERTIFIED: '/images/pga-member-logo.svg',
    SPECIALIZED: '/images/pga-member-logo.svg',
    MASTER: '/images/pga-master-logo.svg',
    MEMBER: '/images/pga-member-logo.svg',
  }

  const associateLogo = '/images/pga-seal-icon.svg'

  if (memberType === 'QE' || !memberType) return null
  if (memberType === 'AP' || memberType === 'ST') {
    return (
      <img
        src={associateLogo}
        alt="PGA Crest"
        style={{ height: size || '65px', width: size || '65px' }}
      />
    )
  }
  return certificationLevel ? (
    <img
      style={{ height: size || '78px', width: size || '78px' }}
      src={getPgaCrest[certificationLevel]}
      alt="PGA Crest"
    />
  ) : null
}

CoachLogo.propTypes = {
  certificationLevel: PropTypes.string,
  memberType: PropTypes.string,
  size: PropTypes.string,
}

export default CoachLogo
