import React, { useState } from 'react'
import get from 'lodash/get'
import TextField from '@material-ui/core/TextField'
import { Controller } from 'react-hook-form'
import Autocomplete from '@material-ui/lab/Autocomplete'
import emailSpellChecker from '@zootools/email-spell-checker'
import PropTypes from 'prop-types'

const EmailFieldWithSuggestions = ({
  control,
  fieldName,
  errors,
  label,
  className,
  ...other
}) => {
  const [emailSuggestion, setEmailSuggestion] = useState('')

  const onChangeEmail = (value) => {
    let email = ''
    email = emailSpellChecker.run({
      email: value || '',
    })
    setEmailSuggestion(email?.full)
  }
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        name, value, ref, onChange, onBlur,
      }) => (
        <Autocomplete
          name={name}
          filterOptions={options => options}
          disableClearable
          freeSolo
          value={value}
          inputRef={ref}
          options={emailSuggestion ? [emailSuggestion] : []}
          onBlur={onBlur}
          onChange={(e, val) => onChange(val)}
          renderInput={params => (
            <TextField
              {...params}
              {...other}
              error={!!get(errors, name)}
              helperText={get(errors, name)?.message}
              label={label}
              className={className}
              onBlur={onBlur}
              onChange={(e) => {
                onChangeEmail(e.target.value)
                onChange(e)
              }}
              autoComplete="email"
            />
          )}
        />
      )}
    />
  )
}

EmailFieldWithSuggestions.propTypes = {
  control: PropTypes.object,
  fieldName: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
}

export default EmailFieldWithSuggestions
