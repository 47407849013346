/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { sortBy, pathOr } from 'ramda'
import { useEffect } from 'react'
import {
  IconMarker, IconFlag,
} from '@components/atoms/icons'
import IconSocial from '@components/atoms/icons/icon-social'
import Embed from '@components/embed/embed'
import CoachProfileLeadForm from '@components/coach-profile-lead-form'
import secureLink from '@utils/secureLink'
import remarkGfm from 'remark-gfm'
import { useTheme } from '@material-ui/core/styles'
import { gtmEvent } from '@lib/gtm-utils'
import useIsMobile from '@hooks/useIsMobile'
import CoachLogo from '@components/coach-logo'
import {
  HR,
  FlexBox,
  NamePicTitle,
  ProfileCircle,
  ProfileLetters,
  CrestImageWrapper,
  ContactInfoItem,
  TitleRoundedBG,
  ContactInfo,
  FollowersAndSocial,
  FollowersWrapper,
  SocialsWrapper,
  BioWrapper,
  SocialIcon,
  useStyles,
} from './coach-bio.styles'

const followersFormatter = (num) => {
  // eslint-disable-next-line no-nested-ternary
  return Math.abs(num) >= 1000000
    ? `${(Math.abs(num) / 1000000).toFixed(1)}M`
    : (Math.abs(num) >= 1000
      ? `${(Math.abs(num) / 1000).toFixed(1)}k`
      : num)
}

const getMemberType = {
  MB: 'member',
  AP: 'associate',
  ST: 'student',
}

const sortByFacilityRank = sortBy(pathOr(10000, ['customData', 'facilityRank']))

const CoachBio = ({ coach }) => {
  const {
    coachProfileId,
    firstName,
    lastName,
    displayName,
    certificationLevel,
    memberType,
    title,
    profilePhoto,
    city,
    state,
    bio,
    numberOfStudents,
    numberOfFollowers,
    socialJsonData,
    facilities,
    introductionVideoUrl,
    bookable,
    coach: { id, preferences, freeTierLimitsReached } = {},
  } = coach
  const isLeadsLimitReached = freeTierLimitsReached.includes('LEADS')
  const { acceptsLeads } = preferences
  const showContactForm = acceptsLeads && !isLeadsLimitReached

  const isMobile = useIsMobile()

  const extractYouTubeVideoId = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const matches = url.match(regExp)
    return matches[7] // video's id regardless of format
  }

  const videoId = introductionVideoUrl
    ? extractYouTubeVideoId(introductionVideoUrl) : null

  const videoProps = {
    provider: 'youtube',
    id: videoId,
    autoplay: false,
  }

  const showIntroductionVideo = !!videoProps.id

  const primaryCourse = pathOr(null, ['0'], sortByFacilityRank(facilities))
  const classes = useStyles()
  const theme = useTheme()

  useEffect(() => {
    (window.dataLayer || []).push({ event: 'optimize.activate' })
  }, [])

  const profileIsActive = coach.status === 'ACTIVE'

  return (
    <div className={classes.outerContainer}>
      <Container maxWidth={false}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            {/* profile picture, name, title, membership */}
            <div className={classes.innerContainer}>
              <NamePicTitle>
                <ProfileCircle>
                  {profilePhoto ? (
                    <img src={secureLink(profilePhoto)} alt="Coach Portrait" />
                  ) : firstName && lastName && (
                    <ProfileLetters>
                      <Typography variant="h1">{`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`}</Typography>
                    </ProfileLetters>
                  )}
                </ProfileCircle>
                <FlexBox direction="row" className={classes.titleAndCrest}>
                  <div>
                    <Typography variant="h3" className={classes.titleName}>
                      {displayName || `${firstName} ${lastName}`}
                    </Typography>
                    <Typography variant={isMobile ? 'body2' : 'overline'} className={classes.coachTitle}>
                      {title}
                    </Typography>
                    {(certificationLevel === 'MASTER' || memberType !== 'MB') && (
                      <TitleRoundedBG className={classes.titleCrest} color={theme.palette.primary.dark}>
                        {certificationLevel === 'MASTER' ? 'master professional' : getMemberType[memberType]}
                      </TitleRoundedBG>
                    )}
                  </div>
                  <CrestImageWrapper>
                    {(certificationLevel || memberType)
                      ? <CoachLogo {...{ memberType, certificationLevel }} />
                      : null
                    }
                  </CrestImageWrapper>
                </FlexBox>
              </NamePicTitle>
              <HR />
              {/* contact info bar */}
              {(city || state || primaryCourse) && (
                <>
                  <ContactInfo className={classes.contactInfo}>
                    {(city || state) && (
                      <ContactInfoItem>
                        <IconMarker />
                        <Typography variant="body2" component="span">{`${city}${city && ', '}${state}`}</Typography>
                      </ContactInfoItem>
                    )}
                    {primaryCourse && (
                      <ContactInfoItem>
                        <IconFlag />
                        <Typography variant="body2" component="span">{primaryCourse.facilityName}, {primaryCourse.city}, {primaryCourse.state}</Typography>
                      </ContactInfoItem>
                    )}
                  </ContactInfo>
                  <HR />
                </>
              )}
              {(bio || numberOfStudents || numberOfFollowers || socialJsonData) && (
                <>
                  <BioWrapper>
                    <div className={classes.bioAndVideo} style={{ justifyContent: showIntroductionVideo ? 'space-between' : 'center' }}>
                      {/* Bio section */}
                      {bio && (
                        <div style={{ flex: 1, marginRight: showIntroductionVideo ? '20px' : '0px' }}>
                          <div className="bioFigures">
                            <Typography variant="overline">{`About ${firstName}`}</Typography>
                            <ReactMarkdown className={classes.bio} remarkPlugins={[remarkGfm]}>
                              {bio}
                            </ReactMarkdown>
                          </div>
                        </div>
                      )}

                      {/* Video section */}
                      {showIntroductionVideo && (
                        <div
                          style={{ flex: 1, maxWidth: '100%', margin: '20px 0' }}
                        >
                          <Embed
                            data-gtm-element-name="coach-intro-video"
                            data-gtm-coach-bookable={bookable}
                            data-gtm-coach-location={`${city}${city && ', '}${state}`}
                            data-gtm-coach-facility={facilities.length > 0 ? facilities[0].facilityName : null}
                            data-gtm-coach-id={id}
                            data-gtm-coach-profile-id={coachProfileId}
                            data-gtm-profile-coach={displayName || `${firstName} ${lastName}`}
                            data-gtm-intro-video-url={introductionVideoUrl}
                            {...videoProps}
                          />
                        </div>
                      )}
                    </div>

                    <FollowersAndSocial>
                      {/* students, followers */}
                      {
                        (numberOfStudents || numberOfFollowers) && (
                          <FollowersWrapper>
                            {numberOfStudents && (
                              <div className="bioFigures">
                                <Typography variant="overline">Students</Typography>
                                <Typography variant="body2" className={classes.socialCount}>{followersFormatter(numberOfStudents)}</Typography>
                              </div>
                            )}
                            {numberOfFollowers && (
                              <div className="bioFigures">
                                <Typography variant="overline">Followers</Typography>
                                <Typography variant="body2" className={classes.socialCount}>{followersFormatter(numberOfFollowers)}</Typography>
                              </div>
                            )}
                          </FollowersWrapper>
                        )
                      }
                      {/* social icon links */}
                      {
                        socialJsonData && socialJsonData.length > 0 ? (
                          <SocialsWrapper>
                            {socialJsonData.map(social => (
                              <SocialIcon
                                aria-label={social.type}
                                key={social.type}
                                href={social.reference}
                                target="_blank"
                                onClick={() => gtmEvent({
                                  event: 'click-coach-social',
                                  attributes: {
                                    platform: social.type,
                                    coach_name: displayName || `${firstName} ${lastName}`,
                                    coach_profile_id: coachProfileId,
                                  },
                                })}
                              >
                                <IconSocial
                                  type={social.type}
                                  target="_blank"
                                />
                              </SocialIcon>
                            ))
                            }
                          </SocialsWrapper>
                        ) : null
                      }
                    </FollowersAndSocial>
                  </BioWrapper>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
      {profileIsActive && showContactForm && (
        <Box className={classes.middleBanner}>
          <Box className={classes.contactButtonContainer}>
            <CoachProfileLeadForm coach={coach} />
          </Box>
        </Box>
      )}
    </div>
  )
}

CoachBio.defaultProps = {}

CoachBio.propTypes = {
  coach: PropTypes.shape(
    {
      coachProfileId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      displayName: PropTypes.string,
      customSlug: PropTypes.string,
      certificationLevel: PropTypes.string,
      memberType: PropTypes.string,
      title: PropTypes.string,
      profilePhoto: PropTypes.string,
      phoneExt: PropTypes.string,
      mobileNumber: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
      bio: PropTypes.string,
      numberOfStudents: PropTypes.number,
      numberOfFollowers: PropTypes.number,
      socialJsonData: PropTypes.array,
      facilities: PropTypes.array,
      introductionVideoUrl: PropTypes.string,
      bookable: PropTypes.bool,
      status: PropTypes.string,
      coach: PropTypes.shape({
        id: PropTypes.string,
        preferences: PropTypes.shape({
          bookNowEnabled: PropTypes.bool,
        }),
      }),
    },
  ),
}

export default CoachBio
