import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import CoachProfileCTAButtons from '@components/coach-profile-cta-button'
import isPgaLink from '@utils/isPgaLink'
import useStyles from './coach-profile-cta-banner.styles'

const CoachProfileCtaBanner = ({ coach }) => {
  const classes = useStyles()
  const { bookable, bookingUrl, coach: { preferences, freeTierLimitsReached } } = coach
  const { acceptsLeads, bookNowEnabled } = preferences
  const isLeadsLimitReached = freeTierLimitsReached.includes('LEADS')
  const showContactForm = acceptsLeads && !isLeadsLimitReached
  const isExternalLink = !isPgaLink(bookingUrl)
  const isBookable = bookable && bookingUrl
  const profileIsActive = coach.status === 'ACTIVE'
  const coachWantsToBeRequested = bookNowEnabled && !bookable
  const showCtaBanner = (bookNowEnabled && isExternalLink)
    || (bookNowEnabled && isBookable)
    || (coachWantsToBeRequested && profileIsActive && showContactForm)
    || (profileIsActive && showContactForm)

  return showCtaBanner ? (
    <>
      <Box className={classes.banner} py={2}>
        <Container mr={0}>
          <Box className={classes.buttonContainer}>
            <Box width="100%">
              <CoachProfileCTAButtons coach={coach} ctaPosition="banner" />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.spacer} />
    </>
  ) : null
}

CoachProfileCtaBanner.propTypes = {
  coach: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    customSlug: PropTypes.string.isRequired,
    coach: PropTypes.shape({
      preferences: PropTypes.shape({
        bookNowEnabled: PropTypes.bool.isRequired,
        acceptsLeads: PropTypes.bool.isRequired,
      }),
      freeTierLimitsReached: PropTypes.array,
    }).isRequired,
    status: PropTypes.string.isRequired,
    bookable: PropTypes.bool.isRequired,
    bookingUrl: PropTypes.string,
  }).isRequired,
}

export default CoachProfileCtaBanner
